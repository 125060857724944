import { MouseEventHandler, PropsWithChildren } from 'react';

import { GlobalClasses } from 'helpers';
import { cx } from 'libs/ui/theme';
// eslint-disable-next-line no-restricted-imports
import { generatePath } from 'react-router-dom';
import { TestAttributeProps } from 'types/testAttribute';

import { ParamsType } from '../types';

import { LinkStyled } from './Link.styles';

/**
 * Props for the Link component.
 * @template Path - The route path type.
 */
export interface LinkProps<Path extends string> {
  /**  The target route path. */
  to: Path;

  /** Parameters for the route path (if applicable). */
  params: ParamsType<Path> | undefined;

  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

/**
 * Link component that generates the correct path with parameters.
 * @template T - The route path type.
 */
export const Link = <T extends string>({
  to,
  params,
  children,
  datax,
  className,
  onClick,
}: LinkProps<T> & PropsWithChildren & TestAttributeProps) => {
  // Generate the path with parameters
  const path = generatePath(to, params);
  // Render the Link component
  return (
    <LinkStyled
      className={cx(GlobalClasses.hoveredChild, className)}
      datax={datax}
      to={path}
      onClick={onClick}
    >
      {children}
    </LinkStyled>
  );
};
