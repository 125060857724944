import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// eslint-disable-next-line no-restricted-imports
import { GlobalStyles, ThemeProvider as ThemeProviderMui } from '@mui/material';
import { LocalStorage } from 'libs/storage';
import { CSSObject } from 'libs/ui/styled';

import { Theme, createTheme, PaletteMode } from './theme';
/**
 * Defines the shape of the theme context object used for theming within the application.
 * @interface ThemeContextType
 */
interface ThemeContextType {
  /** The theme configuration */
  theme: Theme;
  /** The current theme mode ('light' or 'dark'). */
  mode: PaletteMode;

  isSystemMode: boolean;
  /** Set theme mode ('light', 'dark' or 'system') */
  setThemeMode: (mode: PaletteMode | 'system') => void;
}

interface ThemeProps {
  mode?: PaletteMode;
}

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

/**
 * ThemeProvider component for managing the application's theme.
 */
export function ThemeProvider({
  children,
  mode: forceMode,
}: ThemeProps & PropsWithChildren): JSX.Element {
  const [systemMode, setSystemMode] = useState<PaletteMode>(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  );

  const themeInLocalStorage = useRef(
    LocalStorage.getItem(LocalStorage.keys.themeMode)
  ).current;

  const localMode = themeInLocalStorage || systemMode;

  const [isSystemMode, setIsSystemMode] = useState(!themeInLocalStorage);

  const [mode, setMode] = useState<PaletteMode>(
    forceMode ?? localMode === 'dark' ? 'dark' : 'light'
  );

  // Function to toggle between 'light' and 'dark' theme modes.
  const setThemeMode = useCallback(
    (mode: PaletteMode | 'system') => {
      if (mode === 'system') {
        setIsSystemMode(true);
        setMode(systemMode);
        LocalStorage.removeItem(LocalStorage.keys.themeMode);
      } else {
        setIsSystemMode(false);
        setMode(mode);
        LocalStorage.setItem(LocalStorage.keys.themeMode, mode);
      }
    },
    [systemMode]
  );

  useEffect(() => {
    if (forceMode) {
      setMode(forceMode);
    }
  }, [forceMode]);

  const theme = useMemo(() => createTheme(mode), [mode]);

  const value = useMemo(
    () => ({ setThemeMode, mode, theme, isSystemMode }),
    [setThemeMode, mode, theme, isSystemMode]
  );

  useEffect(() => {
    const metaColor = document.querySelector("meta[name='theme-color']");
    if (metaColor) {
      metaColor.setAttribute('content', theme.palette.body);
    }
  }, [theme]);

  useEffect(() => {
    const onEventChange = (evt: MediaQueryListEvent) => {
      setSystemMode(evt.matches ? 'dark' : 'light');
      if (isSystemMode) {
        setMode(evt.matches ? 'dark' : 'light');
      }
    };
    const mq = window.matchMedia?.('(prefers-color-scheme: dark)');
    setSystemMode(mq?.matches ? 'dark' : 'light');

    mq?.addEventListener('change', onEventChange);

    return () => {
      mq?.removeEventListener('change', onEventChange);
    };
  }, [isSystemMode]);

  return (
    <ThemeContext.Provider value={value}>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: theme.palette.body,
          } as CSSObject,
        }}
      />
      <ThemeProviderMui theme={theme}>{children}</ThemeProviderMui>
    </ThemeContext.Provider>
  );
}
/**
 * Hook for accessing the theme context and functions.
 * @returns {ThemeContextType} The theme context and functions.
 */
export const useTheme = (): ThemeContextType => useContext(ThemeContext);
