export enum OnboardingProcessStepNameType {
  ClientRepresentativeAddresses = 'ClientRepresentative_Addresses',
  ClientRepresentativeBankAccounts = 'ClientRepresentative_BankAccounts',
  ClientRepresentativeBiography = 'ClientRepresentative_Biography',
  ClientRepresentativeCitizenships = 'ClientRepresentative_Citizenships',
  ClientRepresentativeCreateClient = 'ClientRepresentative_CreateClient',
  ClientRepresentativeDebts = 'ClientRepresentative_Debts',
  ClientRepresentativeKyc = 'ClientRepresentative_Kyc',
  ClientRepresentativePep = 'ClientRepresentative_Pep',
  ClientRepresentativeBasisForRepresentation = 'ClientRepresentative_BasisForRepresentation',
  ClientRepresentativeTaxResidencies = 'ClientRepresentative_TaxResidencies',
  ClientAddresses = 'Client_Addresses',
  ClientBankAccounts = 'Client_BankAccounts',
  ClientBiography = 'Client_Biography',
  ClientCitizenships = 'Client_Citizenships',
  ClientConfirmDataAccuracy = 'Client_ConfirmDataAccuracy',
  ClientDebts = 'Client_Debts',
  ClientFinancingSources = 'Client_FinancingSources',
  ClientKyc = 'Client_Kyc',
  ClientOrderSubmissionContacts = 'Client_OrderSubmissionContacts',
  ClientPep = 'Client_Pep',
  ClientSignatureCards = 'Client_SignatureCards',
  ClientTaxResidencies = 'Client_TaxResidencies',
  ClientWithdrawalAccounts = 'Client_WithdrawalAccounts',
  LegalClientBeneficiaryAddresses = 'LegalClientBeneficiary_Addresses',
  LegalClientBeneficiaryCitizenships = 'LegalClientBeneficiary_Citizenships',
  LegalClientBeneficiaryControlOverLegalClient = 'LegalClientBeneficiary_ControlOverLegalClient',
  LegalClientBeneficiaryPep = 'LegalClientBeneficiary_Pep',
  LegalClientBeneficiaryPersonalData = 'LegalClientBeneficiary_PersonalData',
  LegalClientBeneficiaryTaxResidencies = 'LegalClientBeneficiary_TaxResidencies',
  LegalClientBranchAddresses = 'LegalClientBranch_Addresses',
  LegalClientBranchContacts = 'LegalClientBranch_Contacts',
  LegalClientBranchName = 'LegalClientBranch_Name',
  LegalClientBranchStateRegistration = 'LegalClientBranch_StateRegistration',
  LegalClientBranchTaxResidencies = 'LegalClientBranch_TaxResidencies',
  LegalClientHeadAddresses = 'LegalClientHead_Addresses',
  LegalClientHeadCitizenships = 'LegalClientHead_Citizenships',
  LegalClientHeadPep = 'LegalClientHead_Pep',
  LegalClientHeadPersonalData = 'LegalClientHead_PersonalData',
  LegalClientHeadPositionInCompany = 'LegalClientHead_PositionInCompany',
  LegalClientHeadTaxResidencies = 'LegalClientHead_TaxResidencies',
  LegalClientHeadPosition = 'LegalClientHead_Position',
  LegalClientLegalHeadFirstHeadAddresses = 'LegalClientLegalHeadFirstHead_Addresses',
  LegalClientLegalHeadFirstHeadCitizenships = 'LegalClientLegalHeadFirstHead_Citizenships',
  LegalClientLegalHeadFirstHeadPep = 'LegalClientLegalHeadFirstHead_Pep',
  LegalClientLegalHeadFirstHeadPersonalData = 'LegalClientLegalHeadFirstHead_PersonalData',
  LegalClientLegalHeadFirstHeadPositionInCompany = 'LegalClientLegalHeadFirstHead_PositionInCompany',
  LegalClientLegalHeadFirstHeadTaxResidencies = 'LegalClientLegalHeadFirstHead_TaxResidencies',
  LegalClientLegalHeadAddresses = 'LegalClientLegalHead_Addresses',
  LegalClientLegalHeadCompanyName = 'LegalClientLegalHead_CompanyName',
  LegalClientLegalHeadFirstHeads = 'LegalClientLegalHead_FirstHeads',
  LegalClientLegalHeadStateRegistration = 'LegalClientLegalHead_StateRegistration',
  LegalClientLegalHeadTaxResidencies = 'LegalClientLegalHead_TaxResidencies',
  LegalClientLegalHeadPosition = 'LegalClientLegalHead_Position',
  LegalClientRepresentativeAddresses = 'LegalClientRepresentative_Addresses',
  LegalClientRepresentativeBiography = 'LegalClientRepresentative_Biography',
  LegalClientRepresentativeCitizenships = 'LegalClientRepresentative_Citizenships',
  LegalClientRepresentativeCompanyAddresses = 'LegalClientRepresentative_CompanyAddresses',
  LegalClientRepresentativeCompanyConstitutionalDocument = 'LegalClientRepresentative_CompanyConstitutionalDocument',
  LegalClientRepresentativeCompanyName = 'LegalClientRepresentative_CompanyName',
  LegalClientRepresentativeCompanyStateRegistration = 'LegalClientRepresentative_CompanyStateRegistration',
  LegalClientRepresentativeCompanyTaxResidencies = 'LegalClientRepresentative_CompanyTaxResidencies',
  LegalClientRepresentativeKyc = 'LegalClientRepresentative_Kyc',
  LegalClientRepresentativePep = 'LegalClientRepresentative_Pep',
  LegalClientRepresentativeRoleInCompany = 'LegalClientRepresentative_RoleInCompany',
  LegalClientRepresentativeTaxResidencies = 'LegalClientRepresentative_TaxResidencies',
  LegalClientActivityTypes = 'LegalClient_ActivityTypes',
  LegalClientAddresses = 'LegalClient_Addresses',
  LegalClientBankAccounts = 'LegalClient_BankAccounts',
  LegalClientBeneficiaries = 'LegalClient_Beneficiaries',
  LegalClientBodies = 'LegalClient_Bodies',
  LegalClientBranches = 'LegalClient_Branches',
  LegalClientBusinessRelationship = 'LegalClient_BusinessRelationship',
  LegalClientConfirmDataAccuracy = 'LegalClient_ConfirmDataAccuracy',
  LegalClientConstitutionalDocument = 'LegalClient_ConstitutionalDocument',
  LegalClientContacts = 'LegalClient_Contacts',
  LegalClientDebts = 'LegalClient_Debts',
  LegalClientFinancialMonitoring = 'LegalClient_FinancialMonitoring',
  LegalClientFinancingSources = 'LegalClient_FinancingSources',
  LegalClientHeads = 'LegalClient_Heads',
  LegalClientInvoice = 'LegalClient_Invoice',
  LegalClientInvoicePaymentConfirmationFiles = 'LegalClient_InvoicePaymentConfirmationFiles',
  LegalClientLicenseInfo = 'LegalClient_LicenseInfo',
  LegalClientName = 'LegalClient_Name',
  LegalClientOrderSubmissionContacts = 'LegalClient_OrderSubmissionContacts',
  LegalClientOrganizationalAndLegalForm = 'LegalClient_OrganizationalAndLegalForm',
  LegalClientOwnershipStructure = 'LegalClient_OwnershipStructure',
  LegalClientRegistrationPlace = 'LegalClient_RegistrationPlace',
  LegalClientSignatureCards = 'LegalClient_SignatureCards',
  LegalClientStateRegistration = 'LegalClient_StateRegistration',
  LegalClientTaxResidencies = 'LegalClient_TaxResidencies',
  LegalClientWithdrawalAccounts = 'LegalClient_WithdrawalAccounts',
  LegalClientCompanyName = 'LegalClient_CompanyName',
}

export interface OnboardingProcessEntity {
  previousSteps: {
    isCompleted: boolean;
    name: OnboardingProcessStepNameType;
    isEditable: boolean;
    isEnabled: boolean;
  }[];
  currentStep: {
    name: OnboardingProcessStepNameType;
    isEditable: boolean;
    isEnabled: boolean;
  };
  totalStepCount: number;
}
