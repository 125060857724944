import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const LogoDark = ({ size, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="40"
      viewBox="0 0 116 40"
      width="116"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M112.313 39.9061V34.2365H115.996V35.3056H113.518V38.837H115.977V39.9061H112.313ZM112.891 37.5329V36.4962H115.458V37.5329H112.891Z"
        fill="#04ACF4"
      />
      <path
        d="M101.123 40C100.7 40 100.309 39.9298 99.9513 39.7894C99.5992 39.6436 99.2911 39.4411 99.0271 39.182C98.7685 38.9174 98.5677 38.6069 98.4246 38.2505C98.2816 37.8887 98.2101 37.4946 98.2101 37.068C98.2101 36.6522 98.2844 36.2688 98.4329 35.9179C98.5814 35.5615 98.7905 35.2537 99.0601 34.9945C99.3296 34.7299 99.646 34.5248 100.009 34.379C100.372 34.2332 100.766 34.1603 101.189 34.1603C101.464 34.1603 101.734 34.1981 101.998 34.2737C102.267 34.3439 102.512 34.4492 102.732 34.5896C102.958 34.7299 103.15 34.8973 103.31 35.0917L102.534 35.9341C102.402 35.8045 102.265 35.6938 102.122 35.602C101.984 35.5048 101.836 35.4319 101.676 35.3833C101.522 35.3293 101.357 35.3023 101.181 35.3023C100.944 35.3023 100.722 35.3455 100.512 35.4319C100.309 35.5183 100.13 35.6398 99.9761 35.7964C99.822 35.9476 99.6982 36.1338 99.6047 36.3552C99.5167 36.5712 99.4727 36.8115 99.4727 37.0761C99.4727 37.3461 99.5167 37.5918 99.6047 37.8131C99.6927 38.0291 99.8165 38.2154 99.9761 38.372C100.136 38.5232 100.325 38.642 100.545 38.7284C100.771 38.8094 101.019 38.8499 101.288 38.8499C101.464 38.8499 101.635 38.8256 101.8 38.777C101.965 38.7284 102.116 38.6609 102.254 38.5745C102.397 38.4881 102.523 38.3882 102.633 38.2748L103.236 39.2224C103.104 39.3682 102.925 39.5005 102.699 39.6193C102.479 39.7381 102.232 39.8326 101.957 39.9028C101.682 39.9676 101.404 40 101.123 40Z"
        fill="#04ACF4"
      />
      <path
        d="M84.812 39.9189V34.2365H85.8287L88.765 38.4821H88.5525C88.5323 38.3143 88.5146 38.152 88.4994 37.995C88.4842 37.8327 88.4691 37.6703 88.4539 37.5079C88.4438 37.3402 88.4337 37.1697 88.4235 36.9965C88.4185 36.8179 88.4134 36.6312 88.4084 36.4364C88.4033 36.2361 88.4008 36.0197 88.4008 35.787V34.2365H89.5161V39.9189H88.4842L85.4873 35.6327L85.7756 35.6408C85.8009 35.9223 85.8211 36.1631 85.8363 36.3633C85.8565 36.5582 85.8717 36.7286 85.8818 36.8747C85.8919 37.0209 85.8995 37.148 85.9046 37.2563C85.9147 37.3645 85.9198 37.4674 85.9198 37.5648C85.9248 37.6622 85.9273 37.765 85.9273 37.8732V39.9189H84.812Z"
        fill="#04ACF4"
      />
      <path
        d="M70.6272 39.919L72.8883 34.2494H73.9941L76.247 39.919H74.9761L73.8126 36.8655C73.7795 36.7791 73.7383 36.6684 73.6888 36.5334C73.6448 36.3985 73.598 36.2554 73.5485 36.1042C73.499 35.953 73.4522 35.8099 73.4082 35.6749C73.3642 35.5345 73.3312 35.4184 73.3092 35.3266L73.532 35.3185C73.499 35.4427 73.4605 35.5723 73.4165 35.7073C73.3779 35.8369 73.3367 35.9719 73.2927 36.1123C73.2487 36.2473 73.2019 36.3796 73.1524 36.5091C73.1029 36.6387 73.0561 36.7656 73.0121 36.8898L71.8485 39.919H70.6272ZM71.6752 38.7284L72.0796 37.7484H74.7286L75.1412 38.7284H71.6752Z"
        fill="#04ACF4"
      />
      <path
        d="M57.673 39.9189V34.2365H58.6897L61.6259 38.4821H61.4135C61.3933 38.3143 61.3756 38.152 61.3604 37.995C61.3452 37.8327 61.33 37.6703 61.3149 37.5079C61.3047 37.3402 61.2946 37.1697 61.2845 36.9965C61.2794 36.8179 61.2744 36.6312 61.2693 36.4364C61.2643 36.2361 61.2617 36.0197 61.2617 35.787V34.2365H62.3771V39.9189H61.3452L58.3482 35.6327L58.6366 35.6408C58.6618 35.9223 58.6821 36.1631 58.6973 36.3633C58.7175 36.5582 58.7327 36.7286 58.7428 36.8747C58.7529 37.0209 58.7605 37.148 58.7655 37.2563C58.7757 37.3645 58.7807 37.4674 58.7807 37.5648C58.7858 37.6622 58.7883 37.765 58.7883 37.8732V39.9189H57.673Z"
        fill="#04ACF4"
      />
      <path
        d="M47.2147 39.919V34.2494H47.8336C47.9382 34.2494 48.0427 34.2494 48.1472 34.2494C48.2518 34.2494 48.3535 34.2494 48.4526 34.2494V39.919H47.2147Z"
        fill="#04ACF4"
      />
      <path
        d="M34.9034 39.919V34.2494H36.1413V39.919H34.9034ZM35.4728 37.6674V36.6225H38.4602V37.6674H35.4728ZM35.4728 35.3428V34.2494H38.8067V35.3428H35.4728Z"
        fill="#04ACF4"
      />
      <path
        d="M100.641 27.4604V12.1677H103.967L109.546 21.2341L107.161 21.2124L112.806 12.1677H116V27.4604H112.39V22.6974C112.39 21.3866 112.419 20.2077 112.478 19.1587C112.551 18.11 112.668 17.0687 112.828 16.0346L113.244 17.1706L108.999 23.7465H107.555L103.398 17.2143L103.814 16.0346C103.974 17.0104 104.083 18.0153 104.142 19.0494C104.214 20.0686 104.252 21.2846 104.252 22.6974V27.4604H100.641Z"
        fill="#F1F1F1"
      />
      <path
        d="M86.5398 27.4604V12.1677H97.2161V15.3355H90.1498V24.2926H97.4349V27.4604H86.5398ZM88.2901 21.1908V18.1756H96.2315V21.1908H88.2901Z"
        fill="#F1F1F1"
      />
      <path
        d="M69.6394 27.4604V12.1677H76.0493C77.1722 12.1677 78.2011 12.357 79.134 12.7357C80.068 13.0998 80.8699 13.6241 81.5407 14.3087C82.227 14.9933 82.7513 15.8016 83.1166 16.7337C83.4809 17.6658 83.6636 18.6926 83.6636 19.8141C83.6636 20.9352 83.4809 21.9699 83.1166 22.9159C82.7513 23.8485 82.227 24.6564 81.5407 25.3417C80.8699 26.0115 80.068 26.536 79.134 26.9142C78.2011 27.278 77.1722 27.4604 76.0493 27.4604H69.6394ZM73.3588 24.9264L72.8552 24.1834H75.9399C76.5529 24.1834 77.0999 24.0814 77.5808 23.8773C78.0628 23.6589 78.4705 23.36 78.8059 22.9818C79.1567 22.6036 79.4261 22.144 79.616 21.6051C79.8059 21.0661 79.8998 20.4695 79.8998 19.8141C79.8998 19.1587 79.8059 18.5615 79.616 18.0226C79.4261 17.4838 79.1567 17.025 78.8059 16.6463C78.4705 16.2676 78.0628 15.9764 77.5808 15.7724C77.0999 15.554 76.5529 15.4447 75.9399 15.4447H72.7891L73.3588 14.7456V24.9264Z"
        fill="#F1F1F1"
      />
      <path
        d="M59.635 27.6787C58.4099 27.6787 57.3015 27.4818 56.3098 27.0892C55.3179 26.6956 54.4646 26.1422 53.7499 25.4281C53.0352 24.7149 52.4882 23.8771 52.109 22.9157C51.7297 21.9398 51.5402 20.8846 51.5402 19.7483C51.5402 18.6123 51.7371 17.571 52.1309 16.6243C52.5393 15.663 53.1154 14.8255 53.8593 14.1119C54.6032 13.3983 55.4784 12.8448 56.4848 12.4516C57.5058 12.0583 58.6143 11.8617 59.8104 11.8617C60.6567 11.8617 61.4513 11.9709 62.1954 12.1894C62.9539 12.4079 63.6392 12.7064 64.2522 13.0851C64.8642 13.4638 65.3823 13.9007 65.8054 14.3959L63.5515 16.7772C63.1872 16.4276 62.8074 16.1363 62.4142 15.9033C62.0344 15.6703 61.6268 15.4882 61.1892 15.3572C60.7516 15.2115 60.2841 15.1387 59.7887 15.1387C59.1468 15.1387 58.5565 15.2552 58.0163 15.4882C57.4912 15.7212 57.0244 16.0489 56.6161 16.4713C56.2223 16.8791 55.9159 17.3671 55.6972 17.9351C55.4784 18.4885 55.369 19.0929 55.369 19.7483C55.369 20.4033 55.4856 21.0154 55.719 21.5833C55.9524 22.1511 56.2732 22.6467 56.6817 23.0692C57.0901 23.4773 57.5714 23.805 58.1257 24.0523C58.6948 24.2852 59.2996 24.4017 59.9415 24.4017C60.3935 24.4017 60.8166 24.3285 61.2109 24.1832C61.6195 24.0379 61.9694 23.8411 62.2604 23.5938C62.5525 23.3454 62.7857 23.0548 62.9612 22.7199C63.1356 22.3695 63.2233 21.9985 63.2233 21.6049V21.0371L63.7053 21.7801H59.4822V18.8745H66.6806C66.7095 19.0347 66.7312 19.2532 66.7456 19.5299C66.7611 19.8066 66.7683 20.0756 66.7683 20.3384C66.7828 20.6002 66.79 20.797 66.79 20.9279C66.79 21.9182 66.6073 22.8291 66.243 23.6587C65.8932 24.4748 65.3968 25.1807 64.7548 25.7784C64.1129 26.3751 63.3544 26.8409 62.4792 27.1758C61.6041 27.5107 60.6567 27.6787 59.635 27.6787Z"
        fill="#F1F1F1"
      />
      <path
        d="M34.8762 27.4604V12.1677H38.2018L46.2314 23.0911L45.5094 22.9818C45.4364 22.5016 45.3781 22.042 45.3344 21.6051C45.2906 21.1681 45.2468 20.7384 45.2031 20.3169C45.1739 19.8941 45.1448 19.4645 45.1156 19.0276C45.101 18.5906 45.0864 18.1173 45.0718 17.6075C45.0718 17.0978 45.0718 16.5444 45.0718 15.9472V12.1677H48.6818V27.4604H45.3125L36.9328 16.1875L38.0487 16.3404C38.1216 17.0395 38.1799 17.6367 38.2237 18.1319C38.282 18.6125 38.3258 19.0421 38.355 19.4208C38.3987 19.7995 38.4279 20.1418 38.4425 20.4478C38.457 20.7539 38.4644 21.0589 38.4644 21.365C38.4789 21.6566 38.4862 21.9843 38.4862 22.3481V27.4604H34.8762Z"
        fill="#F1F1F1"
      />
      <path
        d="M5.08039 37.4066L0 39.8964V16.5168C0 13.9175 1.48932 11.5371 3.85553 10.388L9.24214 7.74768V30.7853C9.25606 33.5898 7.62755 36.148 5.08039 37.4066Z"
        fill="url(#paint0_linear_476_1751)"
      />
      <path
        d="M9.2561 22.9739L6.69503 20.0189C3.2849 16.079 4.55152 10.046 9.2561 7.74768V22.9739Z"
        fill="url(#paint1_linear_476_1751)"
      />
      <path
        d="M9.2561 7.73401V22.9739L16.4522 32.509V17.2555L9.2561 7.73401Z"
        fill="#0F4B61"
      />
      <path
        d="M22.3816 29.6088L16.4521 32.5091V9.47144C16.4521 6.66697 18.0667 4.09507 20.6278 2.85017L25.7082 0.360352V24.3282C25.7082 26.5581 24.4137 28.6102 22.3816 29.6088Z"
        fill="url(#paint2_linear_476_1751)"
      />
      <path
        d="M16.4521 32.509V17.2555L19.0132 20.2378C22.4233 24.164 21.1567 30.1971 16.4521 32.509Z"
        fill="url(#paint3_linear_476_1751)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_476_1751"
          x1="4.62185"
          x2="4.62185"
          y1="7.73976"
          y2="39.8922"
        >
          <stop stopColor="#0F4B61" />
          <stop offset="0.1273" stopColor="#0E516A" />
          <stop offset="0.3349" stopColor="#0D6182" />
          <stop offset="0.597" stopColor="#0A7BAA" />
          <stop offset="0.9006" stopColor="#059FE1" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_476_1751"
          x1="7.00596"
          x2="7.00596"
          y1="22.9696"
          y2="7.73976"
        >
          <stop stopColor="#077BA8" />
          <stop offset="1" stopColor="#068CC4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_476_1751"
          x1="21.0804"
          x2="21.0804"
          y1="32.5065"
          y2="0.354031"
        >
          <stop stopColor="#0F4B61" />
          <stop offset="0.1273" stopColor="#0E516A" />
          <stop offset="0.3349" stopColor="#0D6182" />
          <stop offset="0.597" stopColor="#0A7BAA" />
          <stop offset="0.9006" stopColor="#059FE1" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_476_1751"
          x1="18.6994"
          x2="18.6994"
          y1="17.2487"
          y2="32.5065"
        >
          <stop stopColor="#077BA8" />
          <stop offset="1" stopColor="#068CC4" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);
