import { PropsWithChildren } from 'react';

import { useIsMobileSize } from 'hooks';

import { SwipeableDrawer } from '../SwipeableDrawer';

import { PopoverStyled } from './Popover.styles';
import { PopoverClasses, PopoverProps } from './types';

/**
 * Menu component.
 */
export const Popover = ({
  isOpen,
  anchorEl,
  children,
  anchorOrigin,
  transformOrigin,
  className,
  onClose,
}: PopoverProps & PropsWithChildren) => {
  const isMobile = useIsMobileSize();

  if (isMobile) {
    return (
      <SwipeableDrawer className={className} isOpen={isOpen} onClose={onClose}>
        {children}
      </SwipeableDrawer>
    );
  }

  return (
    <PopoverStyled
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      classes={{ paper: 'Popover-paper' }}
      className={className}
      open={isOpen}
      transformOrigin={transformOrigin}
      onClose={onClose}
    >
      {children}
    </PopoverStyled>
  );
};

Popover.classes = PopoverClasses;
