import { memo } from 'react';

import 'libs/i18n/instanse';
import { ApiClientProvider } from 'api/client';
import { useTranslation } from 'libs/i18n';
import { RouterProvider } from 'libs/navigation/router';
import { NotificationsProvider } from 'libs/notify';
import { UserProvider } from 'libs/providers';
import { Snackbar } from 'libs/ui';
import { ThemeProvider } from 'libs/ui/theme';

export const App = memo(() => {
  const { language } = useTranslation();
  return (
    <ThemeProvider>
      <NotificationsProvider
        renderItem={({ state, close }) => (
          <Snackbar
            text={state.message?.text}
            variant={state.message?.type}
            visible={state.visible}
            onClose={close}
          />
        )}
      >
        <ApiClientProvider language={language}>
          <UserProvider>
            <RouterProvider />
          </UserProvider>
        </ApiClientProvider>
      </NotificationsProvider>
    </ThemeProvider>
  );
});
