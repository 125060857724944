import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadSaveHeadPositionMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveHeadPositionParam } from 'api/types/params';

import { parseLegalClientLegalHeadEntity } from './helpers';

export const useLegalClientLegalHeadSaveHeadPosition = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadSaveHeadPositionMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientLegalHeadSaveHeadPosition
      ? parseLegalClientLegalHeadEntity(
          data.legalClientLegalHeadSaveHeadPosition
        )
      : undefined,
    loading,
    error,
    saveHeadPosition: useCallback(
      async (id: string, legalHeadId: string, param: SaveHeadPositionParam) => {
        const res = await handle({
          variables: {
            id,
            legalHeadId,
            input: param,
          },
        });
        return res?.data?.legalClientLegalHeadSaveHeadPosition
          ? parseLegalClientLegalHeadEntity(
              res.data.legalClientLegalHeadSaveHeadPosition
            )
          : undefined;
      },
      [handle]
    ),
  };
};
