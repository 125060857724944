// eslint-disable-next-line no-restricted-imports
import { Backdrop } from '@mui/material';
import { styled } from 'libs/ui/styled';

export const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light'
      ? 'rgba(12, 24, 44, 0.75)'
      : 'rgba(43, 53, 74, 0.68)',

  zIndex: theme.zIndex.drawer + 1,
}));
