import { useCallback, useEffect } from 'react';

import { useRegistrationPasswordValidationRule } from 'api/requests';
import {
  useValidatePasswordChange,
  useConfirmPasswordChange,
} from 'api/requests/user';
import { ErrorCode } from 'api/types/error';
import { useObjectState } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNavigate, usePageTitle } from 'libs/navigation';
import { useNotify } from 'libs/notify';
import { useDialog } from 'libs/ui/Dialog/useDialog';

import {
  ChangePasswordFormSubmit,
  FormErrors,
} from '../forms/changePasswordForm';

export const useChangePassword = () => {
  const { goBack } = useNavigate();
  const notify = useNotify();
  const { t } = useTranslation();

  const {
    isOpen: isOpenVerificationDialog,
    onClose: onCloseVerificationDialog,
    open: openVerificationDialog,
  } = useDialog();

  const {
    validatePasswordChange,
    loading: validateLoading,
    error: validateError,
  } = useValidatePasswordChange();
  const { confirmPasswordChange, error: confirmError } =
    useConfirmPasswordChange();

  const { passwordValidationRule } = useRegistrationPasswordValidationRule();

  const [formError, setFormError] = useObjectState<FormErrors>({});

  const onSubmit: ChangePasswordFormSubmit = useCallback(
    async (values) => {
      if (!values.otpCode) {
        const res = await validatePasswordChange({
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        });

        if (res) {
          openVerificationDialog();
        }
      } else {
        const res = await confirmPasswordChange({
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          otpToken: values.otpCode,
        });
        if (res) {
          notify.info(t('user.changePassword.successChangeMessage'));
          goBack();
        }
      }
    },
    [
      confirmPasswordChange,
      goBack,
      notify,
      openVerificationDialog,
      t,
      validatePasswordChange,
    ]
  );

  const handleOnCloseVerificationDialog = () => {
    onCloseVerificationDialog();
    setFormError({ otpCode: undefined });
  };

  useEffect(() => {
    if (validateError || confirmError) {
      if (confirmError?.code === ErrorCode.INVALID_OTP_TOKEN) {
        setFormError({
          oldPassword: undefined,
          newPassword: undefined,
          otpCode: confirmError.message,
        });
      } else if (validateError?.code === ErrorCode.INCORRECT_PASSWORD) {
        setFormError({
          oldPassword: validateError.message,
          newPassword: undefined,
          otpCode: undefined,
        });
      } else if (validateError?.code === ErrorCode.OLD_PASSWORD) {
        setFormError({
          newPassword: validateError.message,
          oldPassword: undefined,
          otpCode: undefined,
        });
      } else {
        setFormError({
          oldPassword: undefined,
          otpCode: undefined,
          newPassword: undefined,
        });
        notify.error(validateError);
      }
    } else {
      setFormError({
        oldPassword: undefined,
        otpCode: undefined,
        newPassword: undefined,
      });
    }
  }, [validateError, notify, setFormError, confirmError]);

  usePageTitle(t('user.changePassword.title'));

  return {
    isOpenVerificationDialog,
    passwordValidationRule,
    submitLoading: validateLoading,
    formError,
    onSubmit,
    onCloseVerificationDialog: handleOnCloseVerificationDialog,
  };
};
