import { PropsWithChildren, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { config } from 'config';

import { createApolloClient } from './helpers/createApolloClient';

interface ApiClientProviderProps extends PropsWithChildren {
  /** Language used for api */
  language: string;
}

/**
 * Aoi provider
 */
export const ApiClientProvider = ({
  language,
  children,
}: ApiClientProviderProps) => {
  const client = useMemo(() => {
    const client = createApolloClient(config.API_URL, language);
    return client;
  }, [language]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const useApiClient = () => useApolloClient();
