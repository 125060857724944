import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/onboarding/common/helpers/getToday';

import { Field } from '../../../forms/onboardingBasisForRepresentationForm';

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={24} spacingMobile={16}>
      <Field.Text
        label={t('onboarding.basisForRepresentation.documentName.label')}
        name="documentName"
        placeholder={t(
          'onboarding.basisForRepresentation.documentName.placeholder'
        )}
      />
      <Field.Text
        showOptionalMark
        label={t('onboarding.basisForRepresentation.documentNumber.label')}
        name="documentNumber"
        placeholder={t(
          'onboarding.basisForRepresentation.documentNumber.placeholder'
        )}
      />
      <Field.DatePicker
        label={t('onboarding.basisForRepresentation.registrationDate.label')}
        maxDate={getToday()}
        name="dateOfIssue"
        placeholder={t(
          'onboarding.basisForRepresentation.registrationDate.placeholder'
        )}
      />
      <Field.DatePicker
        disableFuture={false}
        label={t('onboarding.basisForRepresentation.expirationDate.label')}
        minDate={getTodayWithResetHours()}
        name="dateOfExpiration"
        placeholder={t(
          'onboarding.basisForRepresentation.expirationDate.placeholder'
        )}
      />
    </Stack>
  );
};
