import { useRef } from 'react';

import { useLegalClientSaveCompanyName } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useUser } from 'libs/providers';
import { CompanyNameFormType } from 'modules/onboarding/common/forms/companyNameForm';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyName = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveCompanyName } = useLegalClientSaveCompanyName();

  const { reClient } = useUser();

  const initialValues = useRef(
    data.legalClient?.fullName
      ? {
          fullName: data.legalClient.fullName,
          shortName: data.legalClient.shortName,
          fullNameEng: data.legalClient.fullNameEng,
          shortNameEng: data.legalClient.shortNameEng,
        }
      : undefined
  ).current;

  const handleSubmit = async (values: CompanyNameFormType) => {
    if (values) {
      const res = await saveCompanyName(data.legalClient!.id, {
        fullName: values.fullName,
        shortName: values.shortName,
        fullNameEng: values.fullNameEng,
        shortNameEng: values.shortNameEng,
      });

      reClient();

      if (res) {
        goToNext();
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit: handleSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
  };
};
