import { getColor } from 'helpers';
import { Box, Pressed } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight, PaletteMode } from 'libs/ui/theme';

export const ContainerStyled = styled(Box)(({ theme }) => ({
  '--themeSwitchWidth': '96px',
  '--themeSwitcHeight': '32px',
  [theme.breakpoints.down('mobile')]: {
    '--themeSwitchWidth': '66px',
    '--themeSwitcHeight': '22px',
  },
  width: 'var(--themeSwitchWidth)',
  height: 'var(--themeSwitcHeight)',
  backgroundColor: getColor(theme, {
    dark: ColorsDark.gray100,
    light: ColorsLight.gray100,
  }),
  borderRadius: 32,
  padding: 2,
  gap: 4,
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  position: 'relative',
}));

export const CircleStyled = styled(Box)<{ activeMode: PaletteMode | 'system' }>(
  ({ theme, activeMode }) => ({
    '--themeSwitchCircleSize': 'calc(var(--themeSwitchWidth) / 3 - 4px)',

    transition: theme.transitions.create(['left', 'right']),
    position: 'absolute',
    borderRadius: '100%',
    height: 'var(--themeSwitchCircleSize)',
    width: 'var(--themeSwitchCircleSize)',

    backgroundColor: getColor(theme, {
      dark: ColorsDark.black,
      light: ColorsLight.white,
    }),

    ...(activeMode === 'light' && {
      left: 'calc(var(--themeSwitchWidth) - var(--themeSwitchCircleSize) - 2px)',
    }),
    ...(activeMode === 'dark' && {
      left: 2,
    }),
    ...(activeMode === 'system' && {
      left: 'calc(var(--themeSwitchWidth) / 3 + 2px)',
    }),
  })
);

export const ThemeButtonStyled = styled(Pressed)<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,

    ...(!isActive && {
      '& .themeSwitch-icon:hover': {
        color: theme.palette.hover,
      },
    }),

    '.themeSwitch-icon': {
      width: 14,
      height: 14,

      [theme.breakpoints.down('mobile')]: {
        height: 10,
        width: 10,
      },

      color: getColor(theme, {
        dark: isActive ? ColorsDark.blue150 : ColorsDark.gray150,
        light: isActive ? ColorsLight.blue150 : ColorsLight.gray300,
      }),
    },
  })
);
