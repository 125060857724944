import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import { useFormContext, Field } from '../../../forms/companyActivitiesForm';

import { Fields, Buttons, Header } from './components';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();
  const { values } = useFormContext();

  return (
    <Stack spacing={32}>
      <Field.Text
        showOptionalMark
        label={t('onboarding.companyActivities.OKPOCode.label')}
        name="OKPOCode"
        placeholder={t('onboarding.companyActivities.OKPOCode.placeholder')}
      />
      {values.activityTypes.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Header index={index} />
          <Stack spacing={24} spacingMobile={16}>
            <Fields index={index} />
          </Stack>

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
