import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadSaveHeadPositionMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveHeadPositionParam } from 'api/types/params';

import { parseLegalClientHeadEntity } from './helpers';

export const useLegalClientHeadSaveHeadPosition = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientHeadSaveHeadPositionMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientHeadSaveHeadPosition
      ? parseLegalClientHeadEntity(data.legalClientHeadSaveHeadPosition)
      : undefined,
    loading,
    error,
    saveHeadPosition: useCallback(
      async (id: string, headId: string, param: SaveHeadPositionParam) => {
        const res = await handle({
          variables: {
            id,
            headId,
            input: param,
          },
        });
        return res?.data?.legalClientHeadSaveHeadPosition
          ? parseLegalClientHeadEntity(res.data.legalClientHeadSaveHeadPosition)
          : undefined;
      },
      [handle]
    ),
  };
};
