import { useRef } from 'react';

import { useClientRepresentativeSaveBasisForRepresentation } from 'api/requests';
import { lodash } from 'helpers';
import { FileUpload, useFilesUpload, useServerErrorNotify } from 'hooks';

import { OnboardingBasisForRepresentationFormType } from '../forms/onboardingBasisForRepresentationForm';

import { useOnboardingContext } from './useOnboardingContext';

export const useBasisForRepresentation = () => {
  const { saveBasisForRepresentation, error, loading } =
    useClientRepresentativeSaveBasisForRepresentation();
  const { goToBack, data, clientId } = useOnboardingContext();

  const basisForRepresentation:
    | OnboardingBasisForRepresentationFormType
    | undefined = data.currentClient.basisForRepresentation
    ? {
        ...data.currentClient.basisForRepresentation,
        fileIds: data.currentClient.basisForRepresentation.files.map(
          (v) => v.id
        ),
      }
    : undefined;

  const initialValues = useRef(basisForRepresentation).current;

  const filesUpload: FileUpload[] | undefined =
    data.currentClient.basisForRepresentation?.files.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(filesUpload);

  const onSubmit = async (values: OnboardingBasisForRepresentationFormType) => {
    await saveBasisForRepresentation(clientId, {
      documentName: values.documentName,
      dateOfExpiration: values.dateOfExpiration,
      dateOfIssue: values.dateOfIssue,
      documentNumber: values.documentNumber || undefined,
      fileIds: lodash.compact(values.fileIds),
    });
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
    submitLoading: loading,
    initialValues,
  };
};
