import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';
import { FileType, FileUpload, Stack } from 'libs/ui';

import {
  FormErrors,
  Field,
  getSchema,
  OnboardingBasisForRepresentationFormType,
  MAX_FILES,
} from '../../forms/onboardingBasisForRepresentationForm';

import { FormContent } from './FormContent';

interface Props {
  initialValues?: OnboardingBasisForRepresentationFormType;
  onSubmit: (values: OnboardingBasisForRepresentationFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload, index: number) => void;
}

export const BasisForRepresentation = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  files,
  onGoBack,
  onAddFiles,
  onRemoveFile,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(t);
  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t('onboarding.basisForRepresentation.subtitle')}
      title={t('onboarding.basisForRepresentation.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <FormContent />

        <Field.FilesUpload
          files={files}
          fileTypes={[FileType.pdf]}
          maxFiles={MAX_FILES}
          name="fileIds"
          title={t('onboarding.basisForRepresentation.documentTitle')}
          onAddFiles={onAddFiles}
          onRemoveFile={onRemoveFile}
        />
      </Stack>
    </FormContainer>
  );
};
