import { FormContainer, FormContainerStepProps } from 'components';
import { ObjectSchema } from 'libs/form';
import { useTranslation } from 'libs/i18n';

import {
  HeadPositionFormType,
  FormErrors,
  getSchema,
  getBaseSchema,
  BaseHeadPositionFormType,
} from '../../forms/headPositionForm';

import { FormContent, FormContentProps } from './FormContent';

interface Props<
  P extends boolean,
  V = BaseHeadPositionFormType | HeadPositionFormType
> extends FormContentProps,
    FormContainerStepProps {
  canGoBack?: boolean;
  formError?: FormErrors;
  submitLoading?: boolean;
  initialValues?: V;
  submitDisabled?: boolean;
  onlyPositionInCompany?: P;
  onSubmit: (values: V) => void;
  onGoBack?: () => void;
}

export const HeadPosition = <P extends boolean>({
  canGoBack,
  onGoBack,
  submitLoading,
  submitDisabled,
  formError,
  step,
  initialValues,
  onSubmit,
  onlyPositionInCompany,
  ...formProps
}: Props<P>) => {
  const { t } = useTranslation();

  const schema: ObjectSchema<BaseHeadPositionFormType | HeadPositionFormType> =
    !onlyPositionInCompany ? getSchema(t) : getBaseSchema(t);

  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t('onboarding.headPosition.subtitle')}
      title={t('onboarding.headPosition.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent
        {...formProps}
        onlyPositionInCompany={onlyPositionInCompany}
      />
    </FormContainer>
  );
};
