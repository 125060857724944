import { OnboardingProcessStepNameType } from 'api/types/entity';

import {
  CompanyNameStep,
  CompanyRegistrationAddressStep,
  CompanyStateRegistrationStep,
  HeadPositionStep,
  TaxResidencyStep,
} from './steps';
import { FirstHeadsStep } from './steps/FirstHeadsStep';
import { RoutePage } from './types';

export const pagesMap: Partial<
  Record<OnboardingProcessStepNameType, RoutePage['component']>
> = {
  [OnboardingProcessStepNameType.LegalClientLegalHeadCompanyName]:
    CompanyNameStep,
  [OnboardingProcessStepNameType.LegalClientLegalHeadPosition]:
    HeadPositionStep,
  [OnboardingProcessStepNameType.LegalClientLegalHeadTaxResidencies]:
    TaxResidencyStep,
  [OnboardingProcessStepNameType.LegalClientLegalHeadStateRegistration]:
    CompanyStateRegistrationStep,
  [OnboardingProcessStepNameType.LegalClientLegalHeadAddresses]:
    CompanyRegistrationAddressStep,
  [OnboardingProcessStepNameType.LegalClientLegalHeadFirstHeads]:
    FirstHeadsStep,
};
