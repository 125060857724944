import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadFirstHeadSavePositionInCompanyMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveBaseHeadPositionParam } from 'api/types/params';

import { parseLegalClientLegalHeadFirstHeadEntity } from './helpers';

export const useLegalClientLegalHeadFirstHeadSavePositionInCompany = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadFirstHeadSavePositionInCompanyMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientLegalHeadFirstHeadSavePositionInCompany
      ? parseLegalClientLegalHeadFirstHeadEntity(
          data.legalClientLegalHeadFirstHeadSavePositionInCompany
        )
      : undefined,
    loading,
    error,
    savePositionInCompany: useCallback(
      async (
        id: string,
        legalHeadId: string,
        firstHeadId: string,
        params: SaveBaseHeadPositionParam
      ) => {
        const res = await handle({
          variables: {
            id,
            legalHeadId,
            firstHeadId,
            input: params,
          },
        });
        return res?.data?.legalClientLegalHeadFirstHeadSavePositionInCompany
          ? parseLegalClientLegalHeadFirstHeadEntity(
              res.data.legalClientLegalHeadFirstHeadSavePositionInCompany
            )
          : undefined;
      },
      [handle]
    ),
  };
};
