import { getColor } from 'helpers';

import { styled } from '../styled';
import { ColorsDark, ColorsLight, Theme } from '../theme';

import { DropzoneState } from './types';

const getBorderColor = (
  theme: Theme,
  props: DropzoneState,
  isError?: Boolean
) => {
  if (props.isDragAccept) {
    return getColor(theme, {
      light: ColorsLight.blue150,
      dark: ColorsDark.blue150,
    });
  }
  if (props.isDragReject) {
    return getColor(theme, {
      light: ColorsLight.red100,
      dark: ColorsDark.red100,
    });
  }
  if (isError) {
    return getColor(theme, {
      light: ColorsLight.red100,
      dark: ColorsDark.red100,
    });
  }
  if (props.isFocused) {
    return getColor(theme, {
      light: ColorsLight.blue150,
      dark: ColorsDark.blue150,
    });
  }

  return getColor(theme, {
    light: ColorsLight.blue100,
    dark: ColorsDark.blue250,
  });
};

export const ContainerStyled = styled('div')<
  DropzoneState & { showFiles: Boolean; isError?: Boolean }
>(({ theme, showFiles, isError, ...props }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  [theme.breakpoints.down('mobile')]: {
    padding: 12,
  },
  borderWidth: 1,
  borderRadius: 12,
  borderColor: getBorderColor(theme, props, isError),
  borderStyle: 'dashed',
  outline: 'none',

  '.filesUpload-arrowFile': {
    rotate: showFiles ? '-180deg' : 0,
  },
}));

export const LineStyled = styled('div')(({ theme }) => ({
  backgroundColor: getColor(theme, {
    light: ColorsLight.blue100,
    dark: ColorsDark.gray200,
  }),
  height: 1,
}));

export const DragActiveStyled = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 50,
  paddingBottom: 50,
}));
