import { useRef } from 'react';

import { useLegalClientLegalHeadFirstHeadSavePositionInCompany } from 'api/requests';
import { useFilesUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { BaseHeadPositionFormType } from 'modules/onboarding/common/forms/headPositionForm';
import {
  getBaseSubmitData,
  getInitialData,
} from 'modules/onboarding/common/helpers/headPosition';

import { useOnboardingContext } from './useOnboardingContext';

export const useHeadPosition = () => {
  const { t } = useTranslation();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const {
    error,
    loading: submitLoading,
    savePositionInCompany,
  } = useLegalClientLegalHeadFirstHeadSavePositionInCompany();

  const initialData = useRef(
    getInitialData(t, undefined, undefined, data.firstHead.position)
  ).current;

  const initialValues = useRef(initialData?.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData.files);

  const handleSubmit = async (values: BaseHeadPositionFormType) => {
    const params = getBaseSubmitData(values);
    await savePositionInCompany(
      data.legalClient.id,
      data.legalHeadId,
      data.firstHead.id,
      params
    );
    goToNext();
  };

  return {
    onSubmit: handleSubmit,
    goToBack,
    submitLoading,
    initialValues,
    files,
    error,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
  };
};
