import { CSSObject, keyframes, styled } from 'libs/ui/styled';

const animation = keyframes`
  0%, 5% {
    transform: scale(var(--s, 1)) translate(0px) perspective(150px) rotateY(0deg);
  }
  33% {
    transform: scale(var(--s, 1)) translate(-10px) perspective(150px) rotateX(0deg);
  }
  66% {
    transform: scale(var(--s, 1)) translate(-10px) perspective(150px) rotateX(-180deg);
  }
  95%, 100% {
    transform: scale(var(--s, 1)) translate(0px) perspective(150px) rotateX(-180deg);
  }
`;

export const LoaderStyled = styled('div')(() => ({
  width: 50,
  aspectRatio: '1',
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 1,

  '.loading-left, .loading-right': {
    animation: `${animation} 1.3s infinite linear alternate`,
  } as CSSObject,

  '.loading-left': {
    marginTop: 17,
  } as CSSObject,

  '.loading-right': {
    '--s': '-1,-1',
  },
}));
