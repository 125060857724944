import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const System = ({ size = 24, color, className }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  color = color || defaultColor;
  return (
    <SvgIcon className={className} size={size}>
      <svg
        color={color}
        fill="none"
        height="10"
        viewBox="0 0 10 10"
        width="10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9510_11849)">
          <path
            d="M8.5 0.5H1.5C1.10218 0.5 0.720644 0.658035 0.43934 0.93934C0.158035 1.22064 0 1.60218 0 2V6C0 6.39782 0.158035 6.77936 0.43934 7.06066C0.720644 7.34196 1.10218 7.5 1.5 7.5H4.5V8.5H2.5C2.36739 8.5 2.24021 8.55268 2.14645 8.64645C2.05268 8.74021 2 8.86739 2 9C2 9.13261 2.05268 9.25979 2.14645 9.35355C2.24021 9.44732 2.36739 9.5 2.5 9.5H7.5C7.63261 9.5 7.75979 9.44732 7.85355 9.35355C7.94732 9.25979 8 9.13261 8 9C8 8.86739 7.94732 8.74021 7.85355 8.64645C7.75979 8.55268 7.63261 8.5 7.5 8.5H5.5V7.5H8.5C8.89782 7.5 9.27936 7.34196 9.56066 7.06066C9.84196 6.77936 10 6.39782 10 6V2C10 1.60218 9.84196 1.22064 9.56066 0.93934C9.27936 0.658035 8.89782 0.5 8.5 0.5ZM9 6C9 6.13261 8.94732 6.25979 8.85355 6.35355C8.75979 6.44732 8.63261 6.5 8.5 6.5H1.5C1.36739 6.5 1.24021 6.44732 1.14645 6.35355C1.05268 6.25979 1 6.13261 1 6V2C1 1.86739 1.05268 1.74021 1.14645 1.64645C1.24021 1.55268 1.36739 1.5 1.5 1.5H8.5C8.63261 1.5 8.75979 1.55268 8.85355 1.64645C8.94732 1.74021 9 1.86739 9 2V6Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_9510_11849">
            <rect fill="white" height="10" width="10" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
