import { Icon } from 'libs/ui/Icon';
import { PaletteMode, useTheme } from 'libs/ui/theme';

import {
  CircleStyled,
  ContainerStyled,
  ThemeButtonStyled,
} from './ThemeSwitch.styles';

/**
 * Switch Component
 */
export const ThemeSwitch = () => {
  const { mode, isSystemMode, setThemeMode } = useTheme();

  const setTheme = (mode: PaletteMode | 'system') => () => setThemeMode(mode);

  const activeMode = isSystemMode ? 'system' : mode;

  return (
    <ContainerStyled datax="themeSwitch" role="button">
      <CircleStyled activeMode={activeMode} className="themeSwitch-circle" />

      <ThemeButtonStyled
        isActive={activeMode === 'dark'}
        onClick={setTheme('dark')}
      >
        <Icon.Moon className="themeSwitch-icon" />
      </ThemeButtonStyled>
      <ThemeButtonStyled
        isActive={activeMode === 'system'}
        onClick={setTheme('system')}
      >
        <Icon.System className="themeSwitch-icon" />
      </ThemeButtonStyled>
      <ThemeButtonStyled
        isActive={activeMode === 'light'}
        onClick={setTheme('light')}
      >
        <Icon.Sun className="themeSwitch-icon" />
      </ThemeButtonStyled>
    </ContainerStyled>
  );
};
