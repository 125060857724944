import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useOnboardingClientIdentityLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { ClientIdentity, ClientIdentityType } from 'api/types/data';

export const useOnboardingClientIdentityLazy = () => {
  const [handle, { data, loading, error: apolloError }] =
    useOnboardingClientIdentityLazyQuery();

  const error = useError(apolloError);

  const clientIdentity: ClientIdentity | undefined =
    data?.onboardingClientIdentity
      ? {
          id: data.onboardingClientIdentity.id,
          type: ClientIdentityType[data.onboardingClientIdentity.type],
        }
      : undefined;

  return {
    getClientIdentity: useCallback(async () => {
      const res = await handle();
      return res.data?.onboardingClientIdentity
        ? {
            id: res.data.onboardingClientIdentity.id,
            type: ClientIdentityType[res.data.onboardingClientIdentity.type],
          }
        : undefined;
    }, [handle]),
    clientIdentity,
    loading,
    error,
  };
};
