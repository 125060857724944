// eslint-disable-next-line no-restricted-imports
import { Popover } from '@mui/material';

import { CSSObject, styled } from '../styled';

import { PopoverClasses } from './types';

export const PopoverStyled = styled(Popover)(({ theme }) => ({
  marginTop: 100,

  [PopoverClasses.paper]: {
    borderRadius: 8,
    backgroundColor: theme.palette.body,
    backgroundImage: 'none',
  } as CSSObject,
}));
