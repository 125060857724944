import { ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Moon = ({
  size = 18,
  className,
  color = ColorsLight.black,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      color={color}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9514_15843)">
        <path
          d="M7.58792 14.0018C6.07592 14.0018 4.56392 13.4978 3.33192 12.5598C1.67992 11.3278 0.671921 9.38179 0.587921 7.29579C0.447921 4.77579 1.81992 2.32579 3.97592 1.02379C5.20792 0.295789 6.57992 -0.0682111 8.02192 0.0157889C8.38592 0.0157889 8.74992 0.309789 8.81992 0.589789C8.95992 0.953789 8.88992 1.31779 8.60992 1.59779C7.46192 2.74579 7.02792 4.34179 7.30792 5.92379C7.81192 8.23379 9.98192 9.81579 12.3619 9.60579C12.7259 9.60579 13.0899 9.74579 13.2999 10.1098C13.5099 10.4038 13.4399 10.8378 13.2299 11.1178C12.2219 12.4898 10.7099 13.4978 9.04392 13.8618C8.59592 13.9318 8.09192 14.0018 7.58792 14.0018ZM6.87392 1.52779C6.14592 1.59779 5.43192 1.89179 4.70392 2.32579C2.96792 3.33379 1.95992 5.27979 2.02992 7.22579C2.09992 8.87779 2.89792 10.4038 4.19992 11.4118C5.50192 12.4198 7.15392 12.7838 8.74992 12.4198C9.75792 12.2098 10.6259 11.7758 11.3399 11.0478C8.74992 10.8378 6.50992 8.87779 5.86592 6.21779C5.57192 4.62179 5.93592 2.89979 6.87392 1.52779Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9514_15843">
          <rect fill="white" height="14" width="14" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
