import { ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Sun = ({
  size = 19,
  className,
  color = ColorsLight.white,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      color={color}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9514_15850)">
        <path
          d="M6.9998 2.1C7.4198 2.1 7.6998 1.82 7.6998 1.4V0.7C7.6998 0.28 7.4198 0 6.9998 0C6.5798 0 6.2998 0.28 6.2998 0.7V1.4C6.2998 1.82 6.5798 2.1 6.9998 2.1Z"
          fill="currentColor"
        />
        <path
          d="M6.9998 11.8984C6.5798 11.8984 6.2998 12.1784 6.2998 12.5984V13.2984C6.2998 13.7184 6.5798 13.9984 6.9998 13.9984C7.4198 13.9984 7.6998 13.7184 7.6998 13.2984V12.5984C7.6998 12.1784 7.4198 11.8984 6.9998 11.8984Z"
          fill="currentColor"
        />
        <path
          d="M11.48 3.50031L11.97 3.01031C12.25 2.73031 12.25 2.31031 11.97 2.03031C11.69 1.75031 11.27 1.75031 10.99 2.03031L10.5 2.52031C10.22 2.80031 10.22 3.22031 10.5 3.50031C10.71 3.78031 11.2 3.78031 11.48 3.50031Z"
          fill="currentColor"
        />
        <path
          d="M2.51982 10.4309L2.02982 10.9209C1.74982 11.2009 1.74982 11.6209 2.02982 11.9009C2.30982 12.1809 2.72982 12.1809 3.00982 11.9009L3.49982 11.4109C3.77982 11.1309 3.77982 10.7109 3.49982 10.4309C3.28982 10.2209 2.79982 10.2209 2.51982 10.4309Z"
          fill="currentColor"
        />
        <path
          d="M13.2999 6.30078H12.5999C12.1799 6.30078 11.8999 6.58078 11.8999 7.00078C11.8999 7.42078 12.1799 7.70078 12.5999 7.70078H13.2999C13.7199 7.70078 13.9999 7.42078 13.9999 7.00078C13.9999 6.58078 13.7199 6.30078 13.2999 6.30078Z"
          fill="currentColor"
        />
        <path
          d="M2.1 7.00078C2.1 6.58078 1.82 6.30078 1.4 6.30078H0.7C0.28 6.30078 0 6.58078 0 7.00078C0 7.42078 0.28 7.70078 0.7 7.70078H1.4C1.82 7.70078 2.1 7.42078 2.1 7.00078Z"
          fill="currentColor"
        />
        <path
          d="M11.48 10.4288C11.2 10.1487 10.78 10.1487 10.5 10.4288C10.22 10.7088 10.22 11.1287 10.5 11.4087L10.99 11.8988C11.27 12.1788 11.69 12.1788 11.97 11.8988C12.25 11.6188 12.25 11.1988 11.97 10.9188L11.48 10.4288Z"
          fill="currentColor"
        />
        <path
          d="M2.51982 3.50031C2.79982 3.78031 3.21982 3.78031 3.49982 3.50031C3.77982 3.22031 3.77982 2.80031 3.49982 2.52031L3.00982 2.03031C2.72982 1.75031 2.30982 1.75031 2.02982 2.03031C1.74982 2.31031 1.74982 2.73031 2.02982 3.01031L2.51982 3.50031Z"
          fill="currentColor"
        />
        <path
          d="M6.9998 2.80078C4.6898 2.80078 2.7998 4.69078 2.7998 7.00078C2.7998 9.31078 4.6898 11.2008 6.9998 11.2008C9.3098 11.2008 11.1998 9.31078 11.1998 7.00078C11.1998 4.69078 9.3098 2.80078 6.9998 2.80078ZM6.9998 9.80078C5.4598 9.80078 4.1998 8.54078 4.1998 7.00078C4.1998 5.46078 5.4598 4.20078 6.9998 4.20078C8.5398 4.20078 9.7998 5.46078 9.7998 7.00078C9.7998 8.54078 8.5398 9.80078 6.9998 9.80078Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9514_15850">
          <rect fill="white" height="14" width="14" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
