import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Logo = ({ size, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="40"
      viewBox="0 0 116 40"
      width="116"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M112.312 39.8395V34.1592H116V35.2303H113.518V38.7684H115.981V39.8395H112.312ZM112.89 37.4619V36.4232H115.461V37.4619H112.89Z"
        fill="#04ACF4"
      />
      <path
        d="M101.109 39.9338C100.684 39.9338 100.293 39.8634 99.9354 39.7228C99.5828 39.5767 99.2744 39.3738 99.01 39.1142C98.7511 38.8491 98.5501 38.538 98.4068 38.181C98.2636 37.8185 98.192 37.4236 98.192 36.9962C98.192 36.5796 98.2664 36.1956 98.4151 35.8439C98.5638 35.4869 98.7731 35.1785 99.043 34.9188C99.3129 34.6537 99.6297 34.4482 99.9932 34.3021C100.357 34.156 100.751 34.083 101.175 34.083C101.45 34.083 101.72 34.1209 101.984 34.1966C102.254 34.2669 102.499 34.3724 102.72 34.5131C102.946 34.6537 103.138 34.8215 103.298 35.0162L102.521 35.8601C102.389 35.7303 102.252 35.6194 102.108 35.5274C101.971 35.4301 101.822 35.357 101.662 35.3083C101.508 35.2542 101.343 35.2272 101.166 35.2272C100.93 35.2272 100.707 35.2705 100.497 35.357C100.293 35.4436 100.114 35.5653 99.9602 35.7222C99.8059 35.8737 99.682 36.0603 99.5884 36.2821C99.5002 36.4985 99.4562 36.7392 99.4562 37.0043C99.4562 37.2748 99.5002 37.521 99.5884 37.7428C99.6765 37.9592 99.8004 38.1458 99.9602 38.3027C100.12 38.4542 100.31 38.5732 100.53 38.6597C100.756 38.7409 101.004 38.7815 101.274 38.7815C101.45 38.7815 101.621 38.7571 101.786 38.7084C101.951 38.6597 102.103 38.5921 102.241 38.5056C102.384 38.419 102.51 38.3189 102.621 38.2053L103.224 39.1547C103.092 39.3008 102.913 39.4333 102.687 39.5524C102.466 39.6714 102.219 39.766 101.943 39.8364C101.668 39.9013 101.39 39.9338 101.109 39.9338Z"
        fill="#04ACF4"
      />
      <path
        d="M84.7775 39.8524V34.1592H85.7954L88.7353 38.4128H88.5226C88.5023 38.2447 88.4846 38.0821 88.4694 37.9248C88.4542 37.7622 88.439 37.5995 88.4238 37.4368C88.4137 37.2688 88.4036 37.098 88.3934 36.9245C88.3884 36.7455 88.3833 36.5585 88.3782 36.3633C88.3732 36.1626 88.3706 35.9458 88.3706 35.7126V34.1592H89.4873V39.8524H88.4542L85.4536 35.5581L85.7422 35.5662C85.7675 35.8482 85.7878 36.0894 85.803 36.2901C85.8233 36.4853 85.8385 36.6561 85.8486 36.8025C85.8587 36.9489 85.8663 37.0763 85.8714 37.1847C85.8815 37.2932 85.8866 37.3962 85.8866 37.4938C85.8916 37.5914 85.8942 37.6944 85.8942 37.8028V39.8524H84.7775Z"
        fill="#04ACF4"
      />
      <path
        d="M70.5753 39.8527L72.8392 34.1724H73.9463L76.202 39.8527H74.9296L73.7646 36.7934C73.7315 36.7069 73.6902 36.596 73.6406 36.4607C73.5966 36.3255 73.5498 36.1821 73.5002 36.0306C73.4506 35.8792 73.4038 35.7358 73.3597 35.6006C73.3157 35.4599 73.2826 35.3436 73.2606 35.2516L73.4837 35.2435C73.4506 35.3679 73.4121 35.4978 73.368 35.633C73.3294 35.7629 73.2881 35.8981 73.2441 36.0388C73.2 36.174 73.1532 36.3065 73.1036 36.4364C73.054 36.5662 73.0072 36.6933 72.9631 36.8178L71.7981 39.8527H70.5753ZM71.6246 38.6598L72.0295 37.6779H74.6817L75.0948 38.6598H71.6246Z"
        fill="#04ACF4"
      />
      <path
        d="M57.6052 39.8524V34.1592H58.6232L61.563 38.4128H61.3503C61.3301 38.2447 61.3123 38.0821 61.2971 37.9248C61.282 37.7622 61.2668 37.5995 61.2516 37.4368C61.2414 37.2688 61.2313 37.098 61.2212 36.9245C61.2161 36.7455 61.2111 36.5585 61.206 36.3633C61.2009 36.1626 61.1984 35.9458 61.1984 35.7126V34.1592H62.3151V39.8524H61.282L58.2813 35.5581L58.57 35.5662C58.5953 35.8482 58.6156 36.0894 58.6308 36.2901C58.651 36.4853 58.6662 36.6561 58.6763 36.8025C58.6865 36.9489 58.6941 37.0763 58.6991 37.1847C58.7093 37.2932 58.7143 37.3962 58.7143 37.4938C58.7194 37.5914 58.7219 37.6944 58.7219 37.8028V39.8524H57.6052Z"
        fill="#04ACF4"
      />
      <path
        d="M47.1343 39.8527V34.1724H47.754C47.8586 34.1724 47.9633 34.1724 48.0679 34.1724C48.1726 34.1724 48.2745 34.1724 48.3736 34.1724V39.8527H47.1343Z"
        fill="#04ACF4"
      />
      <path
        d="M34.8079 39.8527V34.1724H36.0472V39.8527H34.8079ZM35.378 37.5968V36.55H38.3689V37.5968H35.378ZM35.378 35.2679V34.1724H38.7159V35.2679H35.378Z"
        fill="#04ACF4"
      />
      <path
        d="M100.529 27.3601V12.0386H103.858L109.444 21.1221L107.056 21.1004L112.708 12.0386H115.906V27.3601H112.292V22.5881C112.292 21.2749 112.321 20.0937 112.379 19.0427C112.453 17.9921 112.57 16.9488 112.73 15.9127L113.146 17.0509L108.896 23.6392H107.451L103.289 17.0947L103.705 15.9127C103.865 16.8904 103.975 17.8972 104.034 18.9333C104.106 19.9544 104.143 21.1726 104.143 22.5881V27.3601H100.529Z"
        fill="#0F4B61"
      />
      <path
        d="M86.4098 27.3601V12.0386H97.0992V15.2123H90.0242V24.1864H97.3182V27.3601H86.4098ZM88.1622 21.0787V18.0577H96.1134V21.0787H88.1622Z"
        fill="#0F4B61"
      />
      <path
        d="M69.4886 27.3601V12.0386H75.9064C77.0306 12.0386 78.0608 12.2282 78.9949 12.6077C79.93 12.9724 80.7329 13.4977 81.4045 14.1836C82.0917 14.8694 82.6166 15.6793 82.9824 16.6131C83.3471 17.547 83.53 18.5757 83.53 19.6993C83.53 20.8226 83.3471 21.8592 82.9824 22.807C82.6166 23.7414 82.0917 24.5508 81.4045 25.2374C80.7329 25.9085 79.93 26.434 78.9949 26.8129C78.0608 27.1774 77.0306 27.3601 75.9064 27.3601H69.4886ZM73.2126 24.8213L72.7084 24.0769H75.7969C76.4106 24.0769 76.9583 23.9747 77.4398 23.7703C77.9223 23.5514 78.3305 23.252 78.6663 22.8731C79.0176 22.4942 79.2873 22.0337 79.4774 21.4937C79.6676 20.9538 79.7616 20.356 79.7616 19.6993C79.7616 19.0427 79.6676 18.4444 79.4774 17.9045C79.2873 17.3647 79.0176 16.905 78.6663 16.5256C78.3305 16.1462 77.9223 15.8544 77.4398 15.6501C76.9583 15.4312 76.4106 15.3218 75.7969 15.3218H72.6422L73.2126 14.6213V24.8213Z"
        fill="#0F4B61"
      />
      <path
        d="M59.472 27.5788C58.2455 27.5788 57.1357 27.3816 56.1428 26.9882C55.1497 26.5938 54.2953 26.0394 53.5798 25.3239C52.8641 24.6095 52.3165 23.7701 51.9369 22.8068C51.5571 21.8291 51.3673 20.7718 51.3673 19.6335C51.3673 18.4953 51.5645 17.452 51.9588 16.5035C52.3676 15.5404 52.9445 14.7014 53.6893 13.9864C54.4341 13.2714 55.3103 12.7169 56.318 12.3229C57.3402 11.9289 58.4501 11.7319 59.6477 11.7319C60.495 11.7319 61.2906 11.8414 62.0356 12.0603C62.7951 12.2791 63.4812 12.5782 64.0949 12.9577C64.7077 13.3371 65.2264 13.7748 65.65 14.2709L63.3933 16.6567C63.0286 16.3065 62.6483 16.0146 62.2547 15.7812C61.8744 15.5478 61.4663 15.3653 61.0281 15.234C60.59 15.0881 60.1219 15.0151 59.626 15.0151C58.9833 15.0151 58.3922 15.1319 57.8514 15.3653C57.3257 15.5988 56.8583 15.9271 56.4494 16.3503C56.0551 16.7588 55.7485 17.2477 55.5294 17.8168C55.3103 18.3713 55.2008 18.9768 55.2008 19.6335C55.2008 20.2897 55.3176 20.903 55.5513 21.4718C55.7849 22.0407 56.1062 22.5373 56.5152 22.9606C56.924 23.3695 57.4059 23.6978 57.9609 23.9456C58.5307 24.1789 59.1362 24.2956 59.7789 24.2956C60.2315 24.2956 60.6551 24.2223 61.0498 24.0767C61.459 23.9311 61.8093 23.7339 62.1007 23.4861C62.3931 23.2373 62.6266 22.9462 62.8023 22.6106C62.9769 22.2596 63.0648 21.8879 63.0648 21.4935V20.9246L63.5473 21.669H59.3191V18.7579H66.5263C66.5552 18.9185 66.5769 19.1374 66.5914 19.4146C66.6069 19.6918 66.6141 19.9614 66.6141 20.2246C66.6286 20.4869 66.6358 20.6841 66.6358 20.8152C66.6358 21.8074 66.4529 22.7201 66.0882 23.5512C65.7379 24.3689 65.2409 25.0761 64.5982 25.6749C63.9554 26.2727 63.196 26.7394 62.3198 27.0749C61.4435 27.4105 60.495 27.5788 59.472 27.5788Z"
        fill="#0F4B61"
      />
      <path
        d="M34.683 27.3601V12.0386H38.0127L46.052 22.9825L45.3292 22.8731C45.2561 22.392 45.1977 21.9315 45.1539 21.4937C45.1101 21.056 45.0663 20.6254 45.0225 20.2032C44.9932 19.7796 44.9641 19.3491 44.9349 18.9114C44.9203 18.4736 44.9056 17.9994 44.891 17.4887C44.891 16.9779 44.891 16.4235 44.891 15.8252V12.0386H48.5055V27.3601H45.132L36.7421 16.0659L37.8593 16.2192C37.9324 16.9196 37.9907 17.5179 38.0346 18.014C38.0929 18.4955 38.1367 18.9259 38.166 19.3054C38.2098 19.6848 38.239 20.0277 38.2536 20.3343C38.2682 20.6409 38.2755 20.9465 38.2755 21.2532C38.2901 21.5454 38.2974 21.8737 38.2974 22.2381V27.3601H34.683Z"
        fill="#0F4B61"
      />
      <path
        d="M5.14178 37.4855L0 40.0001V16.3456C0 13.7203 1.51725 11.3162 3.9055 10.1418L9.35635 7.4751V30.7843C9.35635 33.6167 7.72672 36.2143 5.14178 37.4855Z"
        fill="url(#paint0_linear_476_2305)"
      />
      <path
        d="M9.35634 22.881L6.77141 19.8965C3.3295 15.9172 4.59388 9.81016 9.35634 7.4751V22.881Z"
        fill="url(#paint1_linear_476_2305)"
      />
      <path
        d="M9.35632 7.4751V22.881L16.6335 32.5252V17.0917L9.35632 7.4751Z"
        fill="#95D9E5"
      />
      <path
        d="M22.6323 29.5958L16.6476 32.525V9.21588C16.6476 6.38341 18.2772 3.78583 20.8622 2.51467L26.0039 0V24.2349C25.9899 26.5147 24.6974 28.5872 22.6323 29.5958Z"
        fill="url(#paint2_linear_476_2305)"
      />
      <path
        d="M16.6335 32.5248V17.0913L19.2325 20.1034C22.6744 24.0965 21.396 30.1898 16.6335 32.5248Z"
        fill="url(#paint3_linear_476_2305)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_476_2305"
          x1="4.6799"
          x2="4.6799"
          y1="7.47333"
          y2="39.9982"
        >
          <stop stopColor="#95D9E5" />
          <stop offset="0.1286" stopColor="#8CD6E6" />
          <stop offset="0.3383" stopColor="#74CFE8" />
          <stop offset="0.6031" stopColor="#4CC2ED" />
          <stop offset="0.9096" stopColor="#15B1F2" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_476_2305"
          x1="7.08997"
          x2="7.08997"
          y1="22.8795"
          y2="7.47333"
        >
          <stop stopColor="#32BBEE" />
          <stop offset="1" stopColor="#4AC3EC" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_476_2305"
          x1="21.3181"
          x2="21.3181"
          y1="32.5267"
          y2="0.00190761"
        >
          <stop stopColor="#95D9E5" />
          <stop offset="0.1286" stopColor="#8CD6E6" />
          <stop offset="0.3383" stopColor="#74CFE8" />
          <stop offset="0.6031" stopColor="#4CC2ED" />
          <stop offset="0.9096" stopColor="#15B1F2" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_476_2305"
          x1="18.911"
          x2="18.911"
          y1="17.092"
          y2="32.5265"
        >
          <stop stopColor="#32BBEE" />
          <stop offset="1" stopColor="#4AC3EC" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);
