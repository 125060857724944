import { Route, DeepReadonly } from '../types';

import { RoutesId } from './routesId';

/** Configuration for the application routes */
export const ROUTES_CONFIG = {
  id: RoutesId.root,
  path: '',
  children: [
    {
      id: RoutesId.home,
      index: true,
    },
    {
      id: RoutesId.notFound,
      path: '*',
      authorizationIdRequired: false,
    },
    {
      id: RoutesId.onboarding,
      path: 'onboarding',
    },
    {
      id: RoutesId.onboardingInfo,
      path: 'onboarding/info',
    },

    // auth
    {
      id: RoutesId.registration,
      path: 'registration',
      authorizationIdRequired: false,
    },
    {
      id: RoutesId.login,
      path: 'auth',
      authorizationIdRequired: false,
    },
    {
      id: RoutesId.loginByToken,
      path: 'auth/token',
      authorizationIdRequired: false,
    },
    {
      id: RoutesId.restorePassword,
      path: 'restore-password',
      authorizationIdRequired: false,
    },

    // user
    {
      id: RoutesId.changePassword,
      path: 'password',
    },
  ],
} as const satisfies DeepReadonly<Route>;
