import { ClientIdentityType } from 'api/types/data';
import { LoadingLayout } from 'components';
import { useUser } from 'libs/providers';

import { ClientContainer } from '../client';
import { ClientWithRepresentative } from '../clientWithRepresentative';
import { Legal } from '../legal';
import { LegalBeneficiary } from '../legalBeneficiary';
import { LegalFirstHead } from '../legalFirstHead';
import { LegalHead } from '../legalHead';
import { RepresentativeContainer } from '../representative';

export const OnboardingRoutes = () => {
  const { client } = useUser();

  switch (client?.type) {
    case ClientIdentityType.Client:
      return <ClientContainer clientId={client.id} />;

    case ClientIdentityType.ClientRepresentative:
      return <RepresentativeContainer clientId={client.id} />;

    case ClientIdentityType.ClientWithRepresentative:
      return <ClientWithRepresentative clientId={client.id} />;

    case ClientIdentityType.LegalClientRepresentative:
      return <Legal clientId={client.id} />;

    case ClientIdentityType.LegalClientHead:
      return <LegalHead clientId={client.id} />;

    case ClientIdentityType.LegalClientLegalHeadFirstHead:
      return <LegalFirstHead clientId={client.id} />;

    case ClientIdentityType.LegalClientBeneficiary:
      return <LegalBeneficiary clientId={client.id} />;

    default:
      return <LoadingLayout />;
  }
};
