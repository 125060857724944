import { FC, PropsWithChildren } from 'react';

import { Stack } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { useTheme } from 'libs/ui/theme';

import { WrapperStyled } from '../Wrapper.styles';

import { HeaderStyled } from './Header.styles';
import { ThemeSwitch } from './ThemeSwitch';
import { User } from './User';

export interface HeaderProps {
  className?: string;
}

export const Header: FC<HeaderProps & PropsWithChildren> = ({ className }) => {
  const { mode } = useTheme();
  return (
    <HeaderStyled className={className}>
      <WrapperStyled className="header-wrapper">
        <div className="header-logo-wrapper">
          {mode === 'dark' ? (
            <Icon.LogoDark className="header-logo" />
          ) : (
            <Icon.Logo className="header-logo" />
          )}
        </div>
        <div className="header-right">
          <Stack
            alignItems="center"
            direction="row"
            spacing={32}
            spacingMobile={12}
          >
            <ThemeSwitch />

            <User />
          </Stack>
        </div>
      </WrapperStyled>
    </HeaderStyled>
  );
};
