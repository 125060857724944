import { HeadPositionEntity } from 'api/types/entity';
import { LegalClientBodyEntity } from 'api/types/entity/legal/legalClientBody';
import { yesOrNot } from 'helpers';
import { FileUpload } from 'hooks/useFilesUpload';
import { TFunc } from 'libs/i18n';

import { HeadPositionFormType } from '../../forms/headPositionForm';

export const getInitialData = (
  t: TFunc,
  body?: LegalClientBodyEntity,
  isFirstHead?: boolean,
  positionInCompany?: HeadPositionEntity | undefined
) => {
  const values: HeadPositionFormType | undefined = positionInCompany
    ? {
        body: body ? { value: body.id!, label: body.name } : null,
        firstHead: yesOrNot(t, isFirstHead) ?? null,
        position: positionInCompany?.position,
        dateOfExpiration: positionInCompany.expirationDate,
        positionConfirmationFileIds:
          positionInCompany.authorityConfirmationFiles.map((v) => v.id),
      }
    : undefined;

  const files: FileUpload[] | undefined =
    positionInCompany?.authorityConfirmationFiles.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );
  return { values, files };
};
