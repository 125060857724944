// eslint-disable-next-line no-restricted-imports
import { LegalClientLegalHeadFirstHeadFragment } from 'api/generated/graphql';
import {
  parseCountryEntity,
  parseKyc,
  parseOnboardingProcessEntity,
  parsePepConnectionEntity,
  parseResidentialAddressEntity,
  parseTaxResidenceEntity,
} from 'api/requests/onboarding/helpers';
import { LegalClientLegalHeadFirstHeadEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseHeadPositionEntity } from '../../helpers/parseHeadPositionEntity';
import { parseLegalClientOnboardingStatus } from '../../helpers/parseLegalClientOnboardingStatus';

export const parseLegalClientLegalHeadFirstHeadEntity = (
  data: LegalClientLegalHeadFirstHeadFragment
): LegalClientLegalHeadFirstHeadEntity => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
  middleName: data.middleName || undefined,
  dateOfBirth: new Date(data.dateOfBirth),
  legalClientLegalHeadId: data.legalClientLegalHeadId || undefined,
  onboarding: {
    process: data.onboarding?.process
      ? parseOnboardingProcessEntity(data.onboarding?.process)
      : undefined,
    isCompletedByRepresentative:
      data.onboarding?.isCompletedByRepresentative ?? false,
  },
  email: data.email,

  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  legalClientOnboardingStatus: data.legalClientOnboardingStatus
    ? parseLegalClientOnboardingStatus(data.legalClientOnboardingStatus)
    : undefined,
  registrationAddress: data.registrationAddress,
  residentialAddress: parseResidentialAddressEntity(data.residentialAddress),
  pepConnection: parsePepConnectionEntity(data),
  position: data.positionInCompany
    ? parseHeadPositionEntity(data.positionInCompany)
    : undefined,

  kyc: parseKyc(data.kyc),
});
