import {
  SaveBaseHeadPositionParam,
  SaveHeadPositionParam,
} from 'api/types/params';
import { formatToISODate } from 'helpers';
import { YesOrNoType } from 'types/yesOrNo';

import {
  BaseHeadPositionFormType,
  HeadPositionFormType,
} from '../../forms/headPositionForm';

export const getBaseSubmitData = (
  values: BaseHeadPositionFormType
): SaveBaseHeadPositionParam => ({
  positionInCompany: values.position,
  positionConfirmationFileIds: values.positionConfirmationFileIds ?? [],
  expirationDate: values.dateOfExpiration
    ? formatToISODate(values.dateOfExpiration)
    : undefined,
});

export const getSubmitData = (
  values: HeadPositionFormType
): SaveHeadPositionParam => ({
  ...getBaseSubmitData(values),
  isFirstHead: values.firstHead?.value === YesOrNoType.Yes,
  legalClientBodyId: values.body?.value!,
});
