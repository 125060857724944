// eslint-disable-next-line no-restricted-imports
import { PositionInCompanyFragment } from 'api/generated/graphql';
import { HeadPositionEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileEntity } from '../../helpers';

export const parseHeadPositionEntity = (
  positionInCompany: PositionInCompanyFragment
): HeadPositionEntity => ({
  position: positionInCompany.positionInCompany,
  authorityConfirmationFiles: lodash
    .compact(positionInCompany.positionConfirmationFiles)
    .map(parseFileEntity),
  expirationDate: positionInCompany.expirationDate
    ? new Date(positionInCompany.expirationDate)
    : undefined,
});
