import { PropsWithChildren } from 'react';

import { PullerStyled, SwipeableDrawerSyled } from './SwipeableDrawer.styles';
import { SwipeableDrawerProps } from './types';

/**
 * SwipeableDrawer component.
 */
export const SwipeableDrawer = ({
  isOpen,
  children,
  className,
  onClose,
}: SwipeableDrawerProps & PropsWithChildren) => (
  <SwipeableDrawerSyled
    disableSwipeToOpen
    anchor="bottom"
    className={className}
    open={isOpen}
    onClose={() => onClose?.()}
    onOpen={() => {}}
  >
    <PullerStyled />
    {children}
  </SwipeableDrawerSyled>
);
