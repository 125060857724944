import { useState, MouseEvent, useCallback } from 'react';

import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Link, ROUTES } from 'libs/navigation';
import { useUser } from 'libs/providers';
import { Box, Pressed, Stack, Typography } from 'libs/ui';

import { PopoverContentStyled, PopoverStyled, UserStyled } from './User.styles';

const parseName = (firstName: string, lastName: string) =>
  (lastName.charAt(0) + firstName.charAt(0)).toUpperCase();

export const User = () => {
  const { t } = useTranslation();

  const { client, user, logout } = useUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const closureEvent = useCallback(
    <T extends Function>(f: T) =>
      () => {
        handleClose();
        return f();
      },
    [handleClose]
  );

  if (!client) {
    return null;
  }

  return (
    <>
      <UserStyled onClick={handleClick}>
        <Typography
          className="clientName"
          variant="bodyBold"
          variantMobile="captionBold"
        >
          {parseName(client.firstName, client.lastName)}
        </Typography>
      </UserStyled>

      <PopoverStyled
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        isOpen={isOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <PopoverContentStyled className="headerUser-popoverContent">
          <Stack className="headerUser-popoverContent-title">
            {client.companyName ? (
              <>
                <Typography
                  className="ellipsisText headerUser-popoverContent-name"
                  title={client.companyName}
                  variant="bodyBold"
                >
                  {client.companyName}
                </Typography>
                <Typography
                  className="ellipsisText headerUser-popoverContent-caption"
                  title={parseFullName(client)}
                >
                  {parseFullName(client)}
                </Typography>
              </>
            ) : (
              <Typography
                className="ellipsisText headerUser-popoverContent-name"
                title={parseFullName(client)}
                variant="bodyBold"
              >
                {parseFullName(client)}
              </Typography>
            )}

            <Typography className="headerUser-popoverContent-caption">
              {user?.email}
            </Typography>
          </Stack>

          <Box className="headerUser-popoverContent-line" />

          {user?.otp?.verified && (
            <>
              <Link
                className="headerUser-popoverContent-action"
                params={{}}
                to={ROUTES.changePassword.fullPath}
                onClick={handleClose}
              >
                <Typography>{t('common.changePassword')}</Typography>
              </Link>
              <Box className="headerUser-popoverContent-line2" />
            </>
          )}

          <Pressed
            className="headerUser-popoverContent-action"
            onClick={closureEvent(logout)}
          >
            <Typography>{t('common.logout')}</Typography>
          </Pressed>
        </PopoverContentStyled>
      </PopoverStyled>
    </>
  );
};
