import { useRef } from 'react';

import {
  useLegalClientCompleteHeadOnboarding,
  useLegalClientLegalHeadSaveHeadPosition,
} from 'api/requests';
import { useFilesUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { HeadPositionFormType } from 'modules/onboarding/common/forms/headPositionForm';
import {
  getSubmitData,
  getInitialData,
} from 'modules/onboarding/common/helpers/headPosition';

import { checkFirstHeadFullness } from '../helpers/checkFirstHeadFullness';

import { useOnboardingContext } from './useOnboardingContext';

export const useHeadPosition = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { t } = useTranslation();

  const {
    error,
    loading: submitLoading,
    saveHeadPosition,
  } = useLegalClientLegalHeadSaveHeadPosition();

  const { complete } = useLegalClientCompleteHeadOnboarding();

  const initialBody = data.legalHead.legalClientBodyId
    ? data.legalClient.bodies?.find(
        (v) => v.id === data.legalHead.legalClientBodyId
      )
    : undefined;

  const initialData = useRef(
    getInitialData(
      t,
      initialBody,
      data.legalHead.isFirstHead,
      data.legalHead.position
    )
  ).current;

  const initialValues = useRef(initialData?.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData.files);

  const onSubmit = async (values: HeadPositionFormType) => {
    if (values) {
      const submitData = getSubmitData(values);

      await saveHeadPosition(
        data.legalClient.id,
        data.legalHead.id,
        submitData
      );

      // complete when the organ has been restored
      if (
        data.legalHead?.firstHeads?.length &&
        data.legalHead?.firstHeads?.every(checkFirstHeadFullness)
      ) {
        complete(data.legalClient.id, data.legalHead.id);
      }

      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    bodies: data.legalClient.bodies,
    submitLoading,
    initialValues,
    error,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
  };
};
