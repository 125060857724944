import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSaveBasisForRepresentationMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { OnboardingClientRepresentativeSaveBasisForRepresentationParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { parseClientRepresentative } from './helpers/parseClientRepresentative';

export const useClientRepresentativeSaveBasisForRepresentation = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientRepresentativeSaveBasisForRepresentationMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientRepresentativeSaveBasisForRepresentation
      ? parseClientRepresentative(
          data.clientRepresentativeSaveBasisForRepresentation
        )
      : undefined,
    loading,
    error,
    saveBasisForRepresentation: useCallback(
      async (
        id: string,
        params: OnboardingClientRepresentativeSaveBasisForRepresentationParam
      ) => {
        const res = await handle({
          variables: {
            id,
            input: {
              ...params,
              dateOfExpiration: formatToISODate(params.dateOfExpiration),
              dateOfIssue: formatToISODate(params.dateOfIssue),
            },
          },
        });
        return res.data?.clientRepresentativeSaveBasisForRepresentation
          ? parseClientRepresentative(
              res.data.clientRepresentativeSaveBasisForRepresentation
            )
          : undefined;
      },
      [handle]
    ),
  };
};
