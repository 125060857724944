import { CSSObject } from 'libs/ui/styled';
import { Theme } from 'libs/ui/theme';

import { ButtonColorVariant } from '../types';

import { getOutlinedMode } from './outlined';

export const getOutlinedSmallMode = (
  theme: Theme,
  colorVariant: ButtonColorVariant
): CSSObject => ({
  ...getOutlinedMode(theme, colorVariant),
  height: 44,
  paddingLeft: 32,
  paddingRight: 32,
  '.button-label': {
    fontWeight: 600,
  },
  '&': {
    [theme.breakpoints.down('mobile')]: {
      height: 32,
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
});
