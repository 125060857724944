import { getColor } from 'helpers';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const HeaderStyled = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.body,
  height: 72,
  width: '100%',
  display: 'flex',

  [theme.breakpoints.down('mobile')]: {
    height: 56,
  },

  '.header-wrapper': {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,
    gap: 12,

    '.header-logo-wrapper': {
      display: 'flex',

      '.header-logo': {
        width: 116,
        height: 40,
        [theme.breakpoints.down('mobile')]: {
          width: 93,
          height: 32,
        },
      },
    },
  },

  '.header-themeSwitch': {
    backgroundColor: getColor(theme, {
      dark: ColorsDark.gray100,
      light: ColorsLight.blue50,
    }),
  },
}));
