import { useTranslation } from 'libs/i18n';
import { HeadPosition } from 'modules/onboarding/common/components';

import { useHeadPosition } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const HeadPositionStep = ({ canGoBack }: PageComponentProps) => {
  const {
    bodies,
    initialValues,
    submitLoading,
    submitDisabled,
    files,
    onSubmit,
    onAddFiles,
    onRemoveFile,
    goToBack,
  } = useHeadPosition();

  const { t } = useTranslation();
  const { step } = useOnboardingContext();

  return (
    <HeadPosition
      bodies={bodies ?? []}
      canGoBack={canGoBack}
      documentTitle={t(
        'onboarding.legalClientLegalHead.headPosition.documentTitle'
      )}
      files={files}
      initialValues={initialValues}
      step={step}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
