import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  mixed,
  array,
  date,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

import { getTodayWithResetHours } from '../helpers/getToday';

export interface BaseHeadPositionFormType {
  position: string;
  dateOfExpiration?: Date | null;
  positionConfirmationFileIds?: string[];
}

export interface HeadPositionFormType extends BaseHeadPositionFormType {
  firstHead?: Maybe<YesOrNoSelectType>;
  body?: Maybe<SelectType<string>>;
}

export const MAX_FILES = 10;
export const maxLengthPosition = 200;

export const getBaseSchema = (
  t: TFunc
): ObjectSchema<BaseHeadPositionFormType> =>
  object({
    positionConfirmationFileIds: array()
      .of(string().required())
      .required()
      .min(1)
      .max(MAX_FILES),

    position: string().max(maxLengthPosition).required(),

    dateOfExpiration: date()
      .min(getTodayWithResetHours(), t('validation.minDate'))
      .nullable(),
  });

export const getSchema = (t: TFunc): ObjectSchema<HeadPositionFormType> =>
  object({
    body: object({
      value: string().required(),
      label: string().required(),
    })
      .default(null)
      .required(),

    firstHead: object({
      value: mixed<YesOrNoType>().oneOf(Object.values(YesOrNoType)).required(),
      label: string().required(),
    })
      .default(null)
      .required(),
  }).concat(getBaseSchema(t));

export const { Field, Form, useFormContext, Submit } =
  TypedForm<HeadPositionFormType>();

export type FormErrors = FormErrorsBase<HeadPositionFormType>;
