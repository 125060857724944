import {
  useLegalClientCreateBody,
  useLegalClientDeleteBody,
  useLegalClientSaveBodiesStep,
  useLegalClientUpdateBody,
} from 'api/requests';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { CompanyStructureFormAuthoritieType } from '../forms/companyStructureForm';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyStructure = () => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const { createBody } = useLegalClientCreateBody();
  const { updateBody } = useLegalClientUpdateBody();
  const { deleteBody } = useLegalClientDeleteBody();

  const { saveBodiesStep, loading: saveBodiesStepLoading } =
    useLegalClientSaveBodiesStep();

  const bodies = data.legalClient?.bodies ?? [];

  const onSubmit = async () => {
    if (!bodies?.length) {
      notify.error(Error(t('onboarding.companyStructure.emptyErrorMessage')));
      return;
    }

    await saveBodiesStep(data.legalClient?.id!);

    goToNext();
  };

  const onAdd = async (value: CompanyStructureFormAuthoritieType) => {
    if (value) {
      return createBody(data.legalClient?.id!, {
        name: value.name.trim(),
        type: value.type.trim(),
      });
    }
    return undefined;
  };

  const onEdit = async (value: CompanyStructureFormAuthoritieType) => {
    if (value?.id) {
      return updateBody(data.legalClient?.id!, value.id, {
        name: value.name.trim(),
        type: value.type.trim(),
      });
    }
    return undefined;
  };

  const onDelete = async (id: string) => {
    if (id) {
      return deleteBody(data.legalClient?.id!, id);
    }
    return undefined;
  };

  return {
    onSubmit,
    goToBack,
    onAdd,
    onEdit,
    onDelete,
    bodies,
    submitLoading: saveBodiesStepLoading,
    addLoading: false,
  };
};
