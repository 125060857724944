// Define an enumeration for route identifiers
export enum RoutesId {
  root = 'root', // root route

  notFound = 'notFound',

  home = 'home',
  // auth
  registration = 'registration',
  login = 'login',
  restorePassword = 'restorePassword',
  loginByToken = 'loginByToken',

  // user
  changePassword = 'changePassword',

  // onboarding
  onboarding = 'onboarding',
  onboardingInfo = 'onboardingInfo',
}
