import { LegalClientBodyEntity } from 'api/types/entity/legal/legalClientBody';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { FileType, FileUpload, Stack } from 'libs/ui';
import { getTodayWithResetHours } from 'modules/onboarding/common/helpers/getToday';

import {
  Field,
  MAX_FILES,
  maxLengthPosition,
} from '../../../forms/headPositionForm';

export interface FormContentProps {
  files?: FileUpload[];
  bodies?: LegalClientBodyEntity[];
  onlyPositionInCompany?: boolean;
  documentTitle?: string;
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const FormContent = ({
  files,
  bodies = [],
  onlyPositionInCompany,
  documentTitle,
  onAddFiles,
  onRemoveFile,
}: FormContentProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      {!onlyPositionInCompany && (
        <>
          <Field.Autocomplete
            hideClearIcon
            label={t('onboarding.headPosition.body.label')}
            name="body"
            optionLabelKey="label"
            options={bodies.map((b) => ({
              value: b.id,
              label: b.name,
            }))}
            optionValueKey="value"
            placeholder={t('onboarding.headPosition.body.placeholder')}
            variant="select"
          />

          <Field.Autocomplete
            hideClearIcon
            label={t('onboarding.headPosition.firstHead.label')}
            name="firstHead"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('onboarding.headPosition.firstHead.placeholder')}
            variant="select"
          />
        </>
      )}

      <Field.Text
        label={t('onboarding.headPosition.position.label')}
        maxLength={maxLengthPosition}
        name="position"
        placeholder={t('onboarding.headPosition.position.placeholder')}
      />

      <Field.DatePicker
        showOptionalMark
        disableFuture={false}
        label={t('onboarding.headPosition.dateOfExpiration.label')}
        minDate={getTodayWithResetHours()}
        name="dateOfExpiration"
        placeholder={t('onboarding.headPosition.dateOfExpiration.placeholder')}
      />

      <Field.FilesUpload
        files={files}
        fileTypes={[FileType.pdf]}
        maxFiles={MAX_FILES}
        name="positionConfirmationFileIds"
        title={documentTitle || t('onboarding.headPosition.documentTitle')}
        onAddFiles={onAddFiles}
        onRemoveFile={onRemoveFile}
      />
    </Stack>
  );
};
